export const PropertyType = {
	INFORMATIVE: 'INFORMATIVE',
	STATISTIC: 'STATISTIC',
	PRECISE: 'PRECISE'
};

export const TimerStyle = {
	DARK: 'DARK',
	GRAPHITE: 'GRAPHITE',
	DARKRED: 'DARKRED',
	RED: 'RED',
	SEA: 'SEA',
	LIGHT: 'LIGHT'
};

export const CalculatorType = {
	HEATING: 'HEATING'
};

export const GraphType = {
	BAR: 'BAR',
	CIRCLE: 'CIRCLE',
	LINE: 'LINE',
	MULTILINE: 'MULTILINE'
};

export const GraphStyle = {
	CLASSIC: 'CLASSIC',
	MODERN: 'MODERN'
};

export const SearchKeyPlace = {
	TOP: 'TOP',
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
	BOTTOM: 'BOTTOM'
};

export const ItemTypeHookType = {
	TEXT: 'TEXT',
	INTEGRATION: 'INTEGRATION'
};

export const ImportFileType = {
	CP_XLS: 'CP_XLS',
	RB_XLS: 'RB_XLS',
	RV_XLS: 'RV_XLS',
	WPG_XLS: 'WPG_XLS',
	POBE_HC_XLS_2022: 'POBE_HC_XLS_2022',
	POBE_HC_XLS_FORECAST_2023: 'POBE_HC_XLS_FORECAST_2023',
	POBE_HC_XLS_2023: 'POBE_HC_XLS_2023',
	CP_LINE_XLS: 'CP_LINE_XLS',
	CP_MULTILINE_XLS: 'CP_MULTILINE_XLS',
	CP_CIRCLE_XLS: 'CP_CIRCLE_XLS'
};

export const RankType = {
	BOROUGH: 'BOROUGH',
	VOIVODESHIP: 'VOIVODESHIP'
};
