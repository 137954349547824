import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col} from 'react-bootstrap';

import LayoutPrivate from '../../../../components/Layout/Private';
import {handleChange, setTitle} from '../../../../util/common';
import {
	Checkbox,
	Form,
	Input,
	Select,
	Textarea
} from '../../../../components/Form';
import {CalculatorType} from '../../../../util/types';
import {Status} from '../../../../components/Other';
import {SuccessMessageTimeout} from '../../../../util/globals';

class EditCalculator extends Component {
	state = {
		success: false,
		invalid: false,
		initialized: false,
		name: '',
		type: '',
		showRefund: false,
		refundCheckbox: '',
		legend: '',
		footer: ''
	}

	componentDidMount() {
		const {calculatorId} = this.props.match.params;
		const {getCalculator} = this.props;

		setTitle(['Kalkulatory', `Kalkulator #${calculatorId}`, 'Edytuj']);

		getCalculator(calculatorId);

		return true;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.item && this.props.item && this.props.item.id === parseInt(this.props.match.params.calculatorId, 10)) {
			this.setState({
				initialized: true,
				name: this.props.item.name,
				type: this.props.item.calculator_type_name,
				showRefund: this.props.item.show_refund === 1,
				refundCheckbox: this.props.item.refund_checkbox ?? '',
				legend: this.props.item.legend ?? '',
				footer: this.props.item.footer ?? ''
			});
		}

		if (!prevProps.edited && this.props.edited) {
			this.props.listCalculators('id', false);

			this.setState({
				success: true
			}, () => {
				setTimeout(() => {
					this.setState({
						success: false
					});

					this.props.history.push('/calculator');
				}, SuccessMessageTimeout);
			});
		}
	}

	handleError = () => {
		this.setState({
			invalid: true,
			success: false
		});
	}

	handleSubmit = () => {
		const {calculatorId} = this.props.match.params;
		const {editCalculator, loading} = this.props;
		const {
			name,
			type,
			showRefund,
			refundCheckbox,
			legend,
			footer
		} = this.state;

		if (loading) return false;

		this.setState({
			invalid: false,
			success: false
		});

		editCalculator(calculatorId, {
			name,
			calculator_type_name: type,
			show_refund: showRefund,
			refund_checkbox: refundCheckbox,
			legend,
			footer
		});

		return true;
	}

	handleChange = (name, value) => handleChange(this, name, value);

	render() {
		const {calculatorId} = this.props.match.params;
		const {loading, error} = this.props;
		const {
			initialized,
			success,
			invalid,
			name,
			type,
			showRefund,
			refundCheckbox,
			legend,
			footer
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col lg={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Edycja kalkulatora #{calculatorId}</h2>
						<Status
							success={success}
							error={error}
							invalid={invalid}
							loading={loading}
						/>
						{
							initialized && (
								<Form handleSubmit={this.handleSubmit} handleError={this.handleError}>
									<Input
										name="name"
										value={name}
										label="Nazwa kalkulatora"
										placeholder="Wpisz nazwę kalkulatora"
										handleChange={this.handleChange}
										required
									/>

									<Select
										name="type"
										value={type}
										label="Rodzaj kalkulatora"
										options={[
											{
												label: 'Wybierz rodzaj',
												value: ''
											},
											{
												label: 'Kalkulator kosztów ogrzewania',
												value: CalculatorType.HEATING
											}
										]}
										handleChange={this.handleChange}
										required
									/>

									<Checkbox
										name="showRefund"
										value={showRefund}
										handleChange={this.handleChange}
										label="Uwzględnij aktualne dopłaty"
										fieldLabel="Dodaj możliwość uwzględniania aktualnych dopłat w kalkulatorze"
									/>

									{
										showRefund && (
											<Input
												name="refundCheckbox"
												value={refundCheckbox}
												label="Niestandardowy tekst dla opcji uwzględnienia dopłat (opcjonalnie)"
												placeholder="Wpisz niestandardowy tekst dla opcji uwzględnienia dopłat (opcjonalnie)"
												handleChange={this.handleChange}
											/>
										)
									}

									<Textarea
										name="legend"
										value={legend}
										label="Legenda dla obliczeń kalkulatora (opcjonalnie)"
										placeholder="Wpisz legendę dla obliczeń kalkulatora (opcjonalnie)"
										handleChange={this.handleChange}
									/>

									<Textarea
										name="footer"
										value={footer}
										label="Stopka kalkulatora (opcjonalnie)"
										placeholder="Wpisz stopkę kalkulatora (opcjonalnie)"
										handleChange={this.handleChange}
									/>

									<Button variant="outline-primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditCalculator.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	item: PropTypes.object,
	getCalculator: PropTypes.func.isRequired,
	edited: PropTypes.bool.isRequired,
	editCalculator: PropTypes.func.isRequired,
	listCalculators: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired
};

EditCalculator.defaultProps = {
	item: null
};

export default EditCalculator;

